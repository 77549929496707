.wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  border: 0;
  outline: transparent;
  background-color: white;
  border-radius: 5px;
  z-index: 99999;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #dfe0e0;
}

.closeBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: transparent;
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99998;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.form label {
  font-family: 'Nunito', sans-serif;
  margin-bottom: 6px;
}

.form input {
  font-size: 0.9375rem;
  font-family: 'Nunito', sans-serif;
  line-height: 1.5;
  border: 1px solid black;
  border-radius: 5px;
  padding: 6px 12px;
  margin-bottom: 16px;
}

.form textarea {
  font-size: 0.9375rem;
  font-family: 'Nunito', sans-serif;
  padding: 10px;
  border-radius: 5px;
  resize: vertical;
  width: 100%;
  height: 200px;
}

.form textarea::placeholder {
  font-size: 0.9375rem;
  font-family: 'Nunito', sans-serif;
}

.sendBtn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding: 12px 6px;
  margin: 10px 0 24px 0;
  background-color: #e9ecec;
  border: 1px solid rgb(128, 128, 128, 0.2);
  border-radius: 5px;
  transition: all 200ms ease;
  cursor: pointer;
}

.sendBtn:hover {
  background-color: rgb(128, 128, 128, 0.4);
}

@media only screen and (max-width: 500px) {
  .wrapper {
    width: calc(100% - 32px);
  }

  .form label {
    font-size: 0.9375rem;
  }

  .form input {
    font-size: 0.9375rem;
    padding: 4px 12px;
  }

  .sendBtn {
    padding: 10px 6px;
  }
}
