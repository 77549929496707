.leftBorder {
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 50%;
  height: 100vh;
}

.info {
  position: relative;
  top: 34%;
  margin-left: calc((100vw - 1280px) / 2);
  width: max-content;
}

.info .header {
  font-size: 2.625rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  border-bottom: 3px solid var(--main-text-color);
  margin-bottom: 32px;
  padding: 0 32px 8px 0;
  position: relative;
  color: var(--main-text-color);
}

.infoItems li {
  margin-bottom: 24px;
  max-width: 90vw;
}

.infoItems li p {
  margin-bottom: 4px;
}

.infoItems li span {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--main-text-color);
}

.infoItems li span img {
  animation: bubble ease-in forwards;
  margin-left: 12px;
  width: 15px;
}

.infoItems li:nth-child(1) span img {
  animation-duration: 800ms;
}
.infoItems li:nth-child(2) span img {
  animation-duration: 1s;
}
.infoItems li:nth-child(3) span img {
  animation-duration: 1.2s;
}

.emailBtn {
  font-size: 1rem;
  font-family: 'Nunito', sans-serif;
  background-color: transparent;
  border: 0;
  outline: transparent;
  cursor: pointer;
}

.emailBtn:hover {
  text-decoration: underline;
}

.phoneNumber {
  font-size: 0.9375rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.phoneLinks {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-left: 2px;
  margin-top: 16px;
}

.socialsBtn {
  display: flex;
  align-items: center;
}

.socialsBtn button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9375rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding: 0 8px;
  margin-top: 6px;
  margin-right: 16px;
  border: 0;
  outline: 0;
  background-color: transparent;
  transition: all 200ms ease-in-out;
  width: max-content;
  height: 40px;
  cursor: pointer;
}

.socialsBtn button:hover {
  background-color: var(--main-color);
  color: white;
}

.socialsBtn button img {
  margin-right: 12px;
  width: 18px;
}

.socialsBtn button:hover img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(359deg) brightness(111%) contrast(101%);
}

@media only screen and (max-width: 1280px) {
  .leftBorder {
    width: 60%;
  }

  .info {
    margin-left: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .leftBorder {
    position: static;
    background: white;
    margin-top: 82px;
    padding: 32px 0;
    width: 100%;
    height: max-content;
  }

  .info {
    margin-left: 16px;
  }

  .info .header {
    font-size: 2.25rem;
    padding: 0 8px 8px 0;
    max-width: calc(100vw - 32px);
  }

  .socialsBtn button {
    font-size: 0.875rem;
  }
}

@keyframes bubble {
  0% {
    transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(1.2) rotate(25deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
