.section {
  margin: 0 auto;
  max-width: 1280px;
}

.building {
  margin-top: 61px;
  position: relative;
  width: 1280px;
}

.building canvas {
  position: absolute;
}

.building .visibleImg {
  position: absolute;
  box-shadow: 0px 0px 2px black;
  object-fit: cover;
  width: 100%;
}

.building .overlayImg {
  position: absolute;
  opacity: 0;
  width: 100%;
}

.building .tooltip {
  position: absolute;
  background-color: #f1f1f1;
  border-radius: 4px;
  left: 0;
  top: 0;
  padding: 8px;
  width: 223px;
  z-index: 10;
}

.building .tooltip div {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 10px solid #f1f1f1;
  border-bottom: 5px solid transparent;
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.building .tooltip p {
  font-size: 0.9375rem;
  font-weight: 600;
  text-align: center;
}

.building .tooltip p span {
  letter-spacing: 1px;
}

@media only screen and (max-width: 1280px) {
  .building {
    width: 100vw;
  }
}

@media only screen and (max-width: 970px) {
  .building {
    margin-top: 81px;
  }
}

@media only screen and (max-width: 760px) {
  .section {
    display: none;
  }
}
