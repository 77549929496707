.section {
  margin-top: 32px;
}

.planWrapper {
  position: relative;
}

.canvas {
  position: absolute;
}

.visibleImg {
  position: absolute;
  width: 960px;
}

.overlayImg {
  position: absolute;
  width: 960px;
  opacity: 0;
}

.apartmentInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 32px;
}

.floorSelection {
  margin-top: 42px;
  margin-right: 32px;
}

.floorSelection button {
  background-color: transparent;
  transition: all 200ms ease-in-out;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.floorSelection button:hover {
  transform: scale(1.1);
}

.floorSelection h1 {
  font-size: 4rem;
  font-family: 'Montserrat', sans-serif;
  margin: 20px 0;
  position: relative;
  color: var(--main-text-color);
}

.floorSelection h1 sub {
  font-size: 1.375rem;
  position: absolute;
  bottom: 12px;
  color: var(--main-text-color);
}

.apartmentInfo {
  display: flex;
  margin-top: 120px;
  opacity: 0;
}

.showApartemntInfo {
  opacity: 1;
}

.apartmentInfo li {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: end;
  margin-bottom: 16px;
  color: var(--main-color);
}

.apartmentInfo li span {
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 8px;
}

.apartmentPreview {
  margin-left: 30px;
  width: 129px;
  height: 120px;
}

@media only screen and (max-width: 1280px) {
  .visibleImg,
  .overlayImg {
    width: 720px;
  }

  .apartmentInfoWrapper {
    align-items: flex-end;
    right: 32px;
  }

  .apartmentPreview {
    height: 100px;
  }

  .apartmentInfo {
    margin-top: 80px;
  }

  .apartmentInfo li {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 900px) {
  .visibleImg,
  .overlayImg,
  .canvas {
    top: 14em;
    width: calc(100% - 16px);
  }

  .apartmentInfoWrapper {
    position: static;
    align-items: center;
  }

  .apartmentInfo {
    display: none;
  }

  .floorSelection {
    margin-top: 24px;
  }
}

@media only screen and (max-width: 420px) {
  .visibleImg,
  .overlayImg,
  .canvas {
    top: 13em;
  }

  .apartmentInfoWrapper {
    margin-top: 48px;
  }

  .floorSelection h1 {
    font-size: 3rem;
  }

  .floorSelection h1 sub {
    font-size: 1.25rem;
    bottom: 8px;
  }
}
