.section {
  display: flex;
  position: relative;
  margin: 0 4em;
  height: 570px;
}

.apartmentPlan {
  position: relative;
  width: 55%;
}

.imagesWrapper {
  overflow: hidden;
  width: 100%;
}

.images {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease;
  margin-top: 20px;
  position: relative;
  width: 634px;
  height: 582px;
}

.arrowsWrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  margin: 0 24px;
  top: 50%;
  transform: translateY(-50%);
  width: calc(100% - 48px);
}

.arrowsWrapper button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 0;
  outline: transparent;
  cursor: pointer;
}

.apartmentImg {
  object-fit: contain;
  width: 100%;
}

.pagination {
  position: relative;
  bottom: -25px;
  left: 40%;
}

.pagination button {
  font-family: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  padding: 6px;
  margin: 0 8px;
  outline: 0;
  border: 1px solid var(--main-color);
  border-radius: 2px;
  cursor: pointer;
}

.pagination .active,
.pagination button:hover {
  background-color: var(--main-color);
  color: white;
}

.planWrapper {
  position: absolute;
  bottom: 250px;
  right: 380px;
}

.canvas {
  position: absolute;
}

.visibleImg {
  position: absolute;
  width: 350px;
}

.overlayImg {
  position: absolute;
  width: 350px;
  opacity: 0;
}

.info {
  margin-top: 4em;
  margin-left: 15em;
  position: relative;
}

.info h2 {
  font-size: 3rem;
  font-family: 'Montserrat', sans-serif;
  text-transform: capitalize;
  background-color: var(--main-color);
  margin-bottom: 16px;
  padding: 6px 12px;
  color: white;
}

.info li {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: end;
  margin-bottom: 16px;
  color: var(--main-text-color);
}

.info li span {
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 8px;
}

.backBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  position: absolute;
  left: 0;
  bottom: 72px;
  background-color: var(--main-color);
  padding: 10px 24px;
  cursor: pointer;
}

.requestBtn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding: 6px 20px;
  border: 1px solid var(--main-color);
  outline: transparent;
  transition: all 200ms ease;
  cursor: pointer;
}

.requestBtn:hover {
  background-color: var(--main-color);
  color: white;
}

@media only screen and (max-width: 1280px) {
  .section {
    margin: 0 auto;
    justify-content: space-between;
    width: 100%;
    height: 500px;
  }

  .images {
    width: 100%;
    height: 100%;
  }

  .planWrapper {
    bottom: 200px;
  }

  .apartmentPlan {
    width: 545px;
  }

  .info {
    margin-left: 0;
    margin-right: 5em;
  }

  .info h2 {
    font-size: 2.5rem;
  }

  .info li {
    font-size: 1rem;
  }

  .info li span {
    font-size: 1.25rem;
  }

  .floorPlan {
    bottom: 32px;
    margin-left: -40px;
  }
}

@media only screen and (max-width: 970px) {
  .planWrapper {
    position: static;
    margin-top: 2em;
    margin-bottom: 10em;
    bottom: 200px;
  }

  .planWrapper div {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;
  }

  .apartmentPlan {
    margin-bottom: 48px;
  }

  .pagination {
    bottom: -26px;
  }

  .section {
    flex-direction: column;
    align-items: center;
  }

  .floorPlan {
    display: none;
  }

  .info {
    margin: 0;
  }

  .info h2 {
    margin-bottom: 0;
    width: max-content;
  }

  .info ul {
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
  }

  .info li {
    border: 1px solid black;
    padding: 6px 24px;
    margin-bottom: 0;
    text-align: start;
  }

  .requestBtn {
    height: 100%;
  }

  .info li:has(button) {
    padding: 0;
    height: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .visibleImg {
    width: 250px;
  }

  .overlayImg {
    width: 250px;
  }

  .section {
    flex-direction: column;
    align-items: center;
  }

  .apartmentPlan {
    width: 325px;
  }

  .info h2 {
    font-size: 1.75rem;
  }

  .info ul {
    grid-template-columns: auto auto;
  }

  .info li {
    border: 1px solid black;
    padding: 4px 16px;
    margin-bottom: 0;
    text-align: start;
  }

  .info li {
    font-size: 0.875rem;
  }

  .info li span {
    font-size: 1rem;
  }
}
