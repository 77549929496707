.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.main img {
  margin-bottom: 32px;
  width: 250px;
}

.main p {
  font-size: 1.125rem;
  font-weight: 600;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .main img {
    width: 200px;
  }

  .main p {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 420px) {
  .main img {
    margin-bottom: 16px;
    width: 150px;
  }

  .main p {
    font-size: 1rem;
  }
}
