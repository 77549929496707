.type {
  display: flex;
  border: 1px solid #111;
  color: var(--main-text-color);
  animation: fadeInScale ease-in-out;
}

.type img {
  margin-right: 12px;
  width: 230px;
  height: 220px;
}

.type h2 {
  font-size: 2.125rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  background-color: var(--main-color);
  line-height: 1.2;
  padding: 8px 12px;
  margin-bottom: 8px;
  margin-top: 28px;
  color: white;
  width: max-content;
}

.type h3 {
  font-size: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 12px;
}

.type h3 sup {
  font-size: 0.875rem;
  font-family: 'Montserrat', sans-serif;
}

.type p {
  font-size: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin: 4px 0;
}

@media only screen and (max-width: 960px) {
  .type img {
    width: 170px;
    height: 177px;
  }

  .type h2 {
    font-size: 1.875rem;
    margin-top: 25px;
  }

  .type h3 {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 436px) {
  .type img {
    width: 152px;
    height: 142px;
  }

  .type h2 {
    font-size: 1.5rem;
    margin-bottom: 4px;
    margin-top: 20px;
  }

  .type h3 {
    font-size: 1rem;
    margin-bottom: 8px;
  }

  .type p {
    font-size: 0.875rem;
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: rotate(-5deg) scale(0.95) translate(-10px, -10px);
  }

  100% {
    opacity: 1;
    transform: rotate(0) scale(1) translate(0, 0);
  }
}
