.main {
  margin: 10em auto 0;
  width: 1280px;
}

.main section {
  display: flex;
  margin: 4.5em 0;
  animation: fadeInBottom 800ms ease-out forwards;
}

.main section:nth-child(2) {
  justify-content: flex-end;
  animation: fadeInBottom 800ms ease-out 0.4s forwards;
  opacity: 0;
}

.main section img {
  box-shadow: 0px 0px 4px var(--main-text-color);
  width: 450px;
}

.main section p {
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 12px;
  width: 650px;
}

.firstText {
  margin-left: 32px;
}

.secondText {
  margin-right: 32px;
  text-align: right;
}

@media only screen and (max-width: 1280px) {
  .main {
    width: calc(100% - 32px);
  }

  .main section img {
    width: 350px;
  }

  .main section p {
    font-size: 1rem;
    width: 450px;
  }
}

@media only screen and (max-width: 900px) {
  .main section img {
    width: 300px;
  }

  .main section p {
    font-size: 0.9375rem;
    margin-top: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 760px) {
  .main {
    margin: 8em auto 0;
  }

  .main section {
    display: flex;
    flex-direction: column;
    margin: 4.5em 0;
  }

  .main section:nth-child(2) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .main section img {
    width: 350px;
  }

  .main section p {
    margin-top: 16px;
    margin-left: 0;
    width: 100%;
  }

  .secondText {
    text-align: left;
  }
}

@media only screen and (max-width: 400px) {
  .main section img {
    width: 100%;
  }

  .main section p {
    font-size: 0.875rem;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
  }
}
