.main {
  margin: 12em auto 0;
  width: 1280px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 4px;
  border-bottom: 2px solid var(--main-text-color);
}

.backBtn {
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: start;
  background-color: transparent;
  padding: 6px 8px;
  outline: 0;
  border: 0;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  color: #111;
}

.backBtn img {
  margin-right: 8px;
}

.backBtn:hover {
  background-color: var(--main-color);
  color: white;
}

.backBtn:hover img {
  filter: invert(100%) sepia(3%) saturate(1251%) hue-rotate(218deg) brightness(216%) contrast(100%);
}

.header h2 {
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  text-align: end;
  background-color: var(--main-color);
  padding: 4px 8px;
  color: white;
}

@media only screen and (max-width: 1280px) {
  .main {
    margin: 10em auto 0;
    width: calc(100% - 32px);
  }
}

@media only screen and (max-width: 900px) {
  .main {
    margin: 6.5em auto 0;
  }

  .header h2 {
    font-size: 1.375rem;
  }
}

@media only screen and (max-width: 500px) {
  .backBtn {
    flex-direction: column;
    align-items: start;
  }

  .backBtn img {
    margin-bottom: 8px;
  }

  .header h2 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .header h2 {
    width: 125px;
  }
}
