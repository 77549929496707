.main {
  margin: 10em auto 0;
  max-width: 1280px;
}

.titleWrapper h1 {
  font-size: 2rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 12px;
  padding: 8px 16px;
  position: relative;
  color: white;
  overflow-x: hidden;
  width: max-content;
  opacity: 0;
  animation: slideIn 800ms cubic-bezier(0.5, 0.1, 0.25, 1) forwards;
}

.titleWrapper h1::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: var(--main-color);
  z-index: -1;
  animation: slideIn 800ms cubic-bezier(0.5, 0.1, 0.25, 1) forwards;
}

.titleWrapper p {
  font-size: 1.0625rem;
  font-weight: 600;
  margin-right: 40px;
}

.types {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px 16px;
  margin-bottom: 3em;
}

@media only screen and (max-width: 1280px) {
  .main {
    width: calc(100% - 32px);
  }

  .types {
    grid-template-columns: 1fr 1fr;
  }

  .titleWrapper p {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .main {
    margin: 8em auto 0;
  }

  .types {
    grid-template-columns: 1fr;
  }

  .titleWrapper h1 {
    font-size: 2.25rem;
  }
}

@media only screen and (max-width: 468px) {
  .titleWrapper h1 {
    font-size: 1.875rem;
    width: 100%;
  }

  .titleWrapper p {
    margin: 8px 0;
  }
}

@keyframes slideIn {
  to {
    left: 0;
    opacity: 1;
  }
}
