.header {
  position: fixed;
  top: 30px;
  width: 100%;
  z-index: 9999;
}

.menuBtn,
.menu {
  display: none;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(15, 23, 42, 0.4);
  backdrop-filter: blur(45px);
  border-radius: 4px;
  -webkit-backdrop-filter: blur(45px);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  padding: 10px 24px 12px;
  margin: 0 auto;
  width: 1280px;
  min-height: 60px;
}

.leftSide,
.leftSide .nav ul,
.rightSide,
.rightSide .icons {
  display: flex;
  align-items: center;
}

.header picture {
  height: 44px;
}

.logo {
  margin-right: 24px;
  margin-top: 2px;
  height: 100%;
  cursor: pointer;
}

.leftSide .nav li {
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  margin: 8px 8px 0;
  padding: 4px 8px;
  transition: all 300ms ease;
  color: white;
  cursor: pointer;
}

.leftSide .nav li:hover,
.leftSide .nav .active {
  background-color: white;
  color: var(--main-text-color);
}

.rightSide .icons button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  margin-left: 12px;
  border: 0;
  outline: 0;
  background-color: transparent;
  transition: all 200ms ease-in-out;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.rightSide .icons button:hover {
  background-color: white;
}

.rightSide .icons button img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(220deg) brightness(103%) contrast(103%);
}

.rightSide .icons button:hover img {
  filter: none;
}

.langSwitcherWrapper {
  position: relative;
  margin-top: 6px;
  margin-left: 30px;
}

.langSwitcherWrapper button {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1;
  padding: 6px 8px;
  background-color: transparent;
  border: 2px solid white;
  outline: 0;
  color: white;
  cursor: pointer;
}

.langSwitcherWrapper .options {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 2px solid white;
  height: 0;
  transition: all 200ms ease-in-out;
  overflow: hidden;
  width: 100%;
  z-index: 1003;
}

.langSwitcherWrapper:hover .options {
  height: 74px;
}

.langSwitcherWrapper .options button {
  font-size: 14px;
  font-family: 'Nunito';
  font-weight: 600;
  text-align: center;
  padding: 8px 0;
  transition: all 200ms ease-in-out;
  color: black;
  cursor: pointer;
}

.langSwitcherWrapper .options button:hover {
  background-color: var(--main-color);
  color: white;
}

.langSwitcherWrapper .options .active {
  display: none;
}

@media only screen and (max-width: 1280px) {
  .headerContainer {
    width: calc(100% - 32px);
  }

  .langSwitcherWrapper {
    margin-left: 20px;
  }

  .logo {
    margin-right: 16px;
  }

  .leftSide .nav li {
    margin: 8px 4px 0;
  }
}

@media only screen and (max-width: 970px) {
  .header {
    top: 0;
  }

  .headerContainer {
    backdrop-filter: none;
    background-color: white;
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    width: 100%;
    min-height: 82px;
  }

  .langSwitcherWrapper {
    margin-top: 0;
  }

  .langSwitcherWrapper button {
    font-weight: 700;
    border: 2px solid var(--main-text-color);
    color: var(--main-text-color);
  }

  .langSwitcherWrapper .options {
    background-color: var(--main-text-color);
    border: 2px solid var(--main-text-color);
  }

  .langSwitcherWrapper .options button {
    color: white;
  }

  .langSwitcherWrapper .options button:hover {
    font-weight: 700;
    background-color: white;
    color: var(--main-color);
  }

  .menuBtn {
    display: block;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin-left: 16px;
    cursor: pointer;
  }

  .menu {
    visibility: hidden;
    opacity: 0;
    transition: all 200ms ease-in-out;
    background-color: white;
    position: absolute;
    left: 0;
    top: 82px;
    width: 100vw;
    height: calc(100vh - 82px);
    z-index: 1000;
  }

  .leftSide .nav {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    transition: all 200ms ease-in-out;
    top: 130px;
    z-index: 1003;
  }

  .leftSide .nav ul {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .leftSide .nav li {
    font-size: 1.5rem !important;
    margin: 0 0 20px 0;
    color: black;
  }

  .leftSide .nav li:hover,
  .leftSide .nav .active {
    background-color: var(--main-color);
    color: white;
  }

  .rightSide .icons {
    visibility: hidden;
    opacity: 0;
    transition: all 200ms ease-in-out;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    top: 75vh;
    z-index: 1003;
  }

  .rightSide .icons button {
    margin: 0 12px;
    width: 46px;
    height: 46px;
  }

  .rightSide .icons button:hover {
    background-color: var(--main-color);
  }

  .rightSide .icons button img {
    filter: none;
    width: 24px;
    height: 24px;
  }

  .rightSide .icons button:hover img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(220deg) brightness(103%) contrast(103%);
  }

  .openedMenu {
    display: flex !important;
    visibility: visible !important;
    opacity: 1 !important;
  }
}

.fontSizeGeo li {
  font-size: 0.875rem !important;
}
