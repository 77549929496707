.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10.5em;
}

.title {
  font-size: 2.5rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  padding-bottom: 12px;
  text-transform: capitalize;
  position: relative;
  max-width: max-content;
  color: var(--main-text-color);
}

.title::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  border-bottom: 2px solid var(--main-text-color);
  animation: slideBorder 3.5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

.tooltip,
.tooltipMobile {
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  padding: 0 8px;
  margin-top: 12px;
  animation: fadeInBottom 1.5s ease-out forwards;
  color: var(--main-text-color);
}

.tooltipMobile {
  display: none;
}

@media only screen and (max-width: 970px) {
  .main {
    margin-top: 12em;
  }

  .title {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 760px) {
  .main {
    margin-top: 9em;
  }

  .title {
    font-size: 1.75rem;
    margin: 0 16px;
  }

  .tooltip {
    display: none;
  }

  .tooltipMobile {
    display: block;
    text-align: left;
    padding: 0 16px;
  }
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}

@keyframes slideBorder {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
