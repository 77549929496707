.wrapper {
  display: none;
  margin: 3em 0 1em 0;
  width: 635px;
}

.wrapper button {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding: 20px;
  margin: 8px 0;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
}

.wrapper button span {
  font-size: 0.9375rem;
  margin-top: 8px;
}

@media only screen and (max-width: 670px) {
  .wrapper {
    display: block;
    width: calc(100% - 32px);
  }
}
