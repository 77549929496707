.mapWrapper {
  background: linear-gradient(-45deg, #919191 0%, #d3d3d3);
  background-size: 400% 400%;
  width: 100vw;
  height: 100vh;
}

@media only screen and (max-width: 900px) {
  .mapWrapper {
    height: 78vh;
  }
}
