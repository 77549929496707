@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

:root {
  --main-color: #4a3576;
  /*  #00687a; */
  --accent-color: #71a14a;
  /*  #f6821d; */
  --main-text-color: #0f0a1d;
  /* #00181f; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: url('./assets/bg.webp');
  background-color: #dee4e9;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

li {
  list-style-type: none;
}

a,
abbr {
  text-decoration: none;
}

address {
  font-style: normal;
}

area {
  cursor: pointer;
}
